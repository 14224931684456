<template>
  <div style="height: 100%">
    <users-list />
  </div>
</template>

<script>
export default {
  name: "Users",
  components: {
    UsersList: () => import("@/views/users/UsersList"),
  },
};
</script>

<style scoped></style>
